(function ($, Drupal, window, document) {

    'use strict';

    $(document).ready(function() {

        $('.block-featured-products.parallax-window').parallax({imageSrc: Drupal.settings.baseUrl +'/'+ Drupal.settings.childThemePath + '/images/featured-products-bg.jpg'});

        //animation fade personnages
        $(window).scroll(function(e) {

            if($('#block-block-2').length > 0)
            {
                animFade();
            }

        });

    });

    function animFade() {
        var halfWindow = Math.floor(window.innerHeight/2);
        var scroolTop = $(window).scrollTop();
        var bloc = $('#block-block-2');
        var blocTop = bloc.offset().top;

        if((halfWindow+scroolTop) >= blocTop )
        {
            if(!bloc.hasClass('fadeIn'))
            {
                bloc.addClass('fadeIn');
            }

        }
        else
        {
            if(bloc.hasClass('fadeIn'))
            {
                bloc.removeClass('fadeIn');
            }
        }
    }

})(jQuery, Drupal, this, this.document);



